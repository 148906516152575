import { TableCell, TableRow, Tooltip, Typography, useTheme } from '@mui/material';

import {
  MealItemCustomAddonResponse,
  MealPhotoQueueResponse,
  MealPushQuestionStatusEnum,
  UsdaNutritionResponse,
} from 'api/generated/MNT';
import type { MealItem } from 'apiClients/mpq';
import { FilterSortTable } from 'components/FilterSortTable';
import ScrollX from 'components/ScrollX';
import type { DraftItem } from 'types/DraftItem';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'App.css';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFoodDetailsPopup } from 'components/FoodDetailsPopup';
import MainCard from 'components/MainCard';
import { MealItemNutrientValue } from 'components/MealItemNutrientValue';
import { formatNumber } from 'food-editor/utils/utils';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useFoodDetails } from 'services/FoodDetailsService';
import { mealItemGetNutrientValue, NUTRIENT_VALUE_LOADING, NutrientDef, nutrientGetDef } from 'utils/mealItems';
import { coalesceNaN } from 'utils/numerical';
import {
  getEmptyMealItem,
  RelevantNutrients,
  usePushQuestions,
  useQueueItemEditor,
  useRelevantNutrients,
} from '../services/QueueItemEditorService';
import { formatMealItemSizing } from './MealBuilder';
import { getMealPushQuestionColor } from './MealPushQuestions';
import { PatientContext } from './usePatientContext';

export const getFoodEditorUrl = (foodName: string) => {
  return `/foods/${encodeURIComponent(foodName)}`;
};

export const checkPctEaten = (percentEaten: number | undefined) => {
  return coalesceNaN(percentEaten, 1);
};

export const getItemWeightWithoutAddons = (
  item: { serving_unit_amount: number, servings: number },
  percentEaten?: number | undefined,
) => {
  return item.serving_unit_amount * item.servings * checkPctEaten(percentEaten);
};

export const getItemWeightTotal = (item: MealItem) => {
  let addonWeight = 0;
  item.custom_addons?.forEach(addon => {
    addonWeight += getItemWeightWithoutAddons(addon, item.percent_eaten);
  });
  return addonWeight + getItemWeightWithoutAddons(item, item.percent_eaten);
};

export const getMealTotalWeight = (draftItems: DraftItem[]) => {
  return _.sum(draftItems.map(item => getItemWeightTotal(item.item)));
};

export const useMealNutrientValues = (opts: {
  draftItems: DraftItem[],
}) => {
  const allFoodNames = useMemo(() => {
    const allFoodNames = [] as string[];
    opts.draftItems.forEach(v => {
      allFoodNames.push(v.item.custom_item && v.searchItem ? v.searchItem.name : v.item.food_name);
      v.item.custom_addons?.forEach(addon => {
        allFoodNames.push(addon.food_name);
      });
    });
    return allFoodNames;
  }, [opts.draftItems]);

  const foodDetails = useFoodDetails(allFoodNames);

  const getItemNutrientWithoutAddons = useCallback((
    item: MealItem | MealItemCustomAddonResponse,
    nutrient: keyof UsdaNutritionResponse,
  ) => {
    const getFoodName = () => {
      if (!('custom_item' in item) || !item.custom_item) {
        return item.food_name;
      }

      const draftItem = opts.draftItems.find(v => v.item.food_name == item.food_name);
      return draftItem?.searchItem?.name || item.food_name;
    };

    const details = foodDetails[getFoodName()];
    if (!details || details.query.isLoading) {
      return NUTRIENT_VALUE_LOADING;
    }

    return mealItemGetNutrientValue({
      item,
      foodNutrients: details?.usda_nutrition ?? null,
      nutrient,
    });
  }, [foodDetails, opts.draftItems]);

  const getItemNutrientTotal = useCallback((item: MealItem, nutrient: keyof UsdaNutritionResponse) => {
    let addonCarbs = 0;
    const percentEaten = checkPctEaten(item.percent_eaten);
    item.custom_addons?.forEach(addon => {
      // percent eaten is only stored on the meal item level, not on the addons
      // so to accurately calculate the meal item nutrient total, apply percent eaten to its addons
      addonCarbs += coalesceNaN(getItemNutrientWithoutAddons(addon, nutrient)?.value * percentEaten, 0);
    });
    return addonCarbs + coalesceNaN(getItemNutrientWithoutAddons(item, nutrient)?.value, 0);
  }, [getItemNutrientWithoutAddons]);

  const getMealTotal = useCallback((nutrient: keyof UsdaNutritionResponse) => {
    return _.sum(opts.draftItems.map(v => getItemNutrientTotal(v.item, nutrient)));
  }, [getItemNutrientTotal, opts.draftItems]);

  return {
    getItemNutrientWithoutAddons,
    getItemNutrientTotal,
    getMealTotal,
  };
};

export const NUTRIENT_WARNING_THRESHOLDS = {
  fibre_g: 5,
  carbohydrates_g: 100,
  netcarb_g: 100,
  energy_kcal: 1000,
  weight_g: 500,
} as const;

export const NUTRIENT_WARNING_WEIGHT_1G = 1;

const NutrientValueWithWarning = (props: {
  nutrientName: string,
  value: number,
  valueStr: string,
}) => {
  const threshold = (NUTRIENT_WARNING_THRESHOLDS as any)[props.nutrientName];
  const isOverWeight = !!threshold && (props.value || 0) >= threshold;
  const isEq1 = props.nutrientName == 'weight_g' && props.value == NUTRIENT_WARNING_WEIGHT_1G;
  if (!(isOverWeight || isEq1)) {
    return <span>{props.valueStr}</span>;
  }

  const errMsg = isOverWeight
    ? `${props.nutrientName} greater than ${formatNumber(threshold)}`
    : `${props.nutrientName} = 1g`;

  return (
    <Tooltip
      title={errMsg}
    >
      <span style={{ whiteSpace: 'nowrap', fontWeight: 'bold', color: 'red' }}>
        {props.valueStr} ⚠️
      </span>
    </Tooltip>
  );
};

type TableDataMealItem = {
  draftItem: DraftItem, // for drag and drop
  dragAndDropIndex: number, // for drag and drop
  type: 'item',
  item: MealItem,
};

type TableDataMealItemAddon = {
  type: 'addon',
  item: MealItemCustomAddonResponse,
  parent: MealItem,
  mealItem: DraftItem, // for drag and drop
  mealItemIndex: number, // for drag and drop
};

type TableDataEmpty = {
  type: 'empty',
  item: MealItem,
};

export type TableDataRow = TableDataMealItem | TableDataMealItemAddon | TableDataEmpty;

export const useMealTable = (props: {
  draftItems: DraftItem[],
  relevantNutrients: RelevantNutrients,
  isForConfirmationDialog?: boolean,
  disableSizingColWrap?: boolean,
  smallerText?: boolean,
  hideNameLink?: boolean,
  isSticky?: boolean,
}) => {
  const summaryRowFontStyle = useMemo(() => {
    return {
      fontWeight: props.isForConfirmationDialog ? 'bold' : 'inherit',
      fontSize: props.smallerText ? '0.8rem' : undefined,
    };
  }, [props.isForConfirmationDialog, props.smallerText]);
  const addOnFontStyle = useMemo(() => {
    return {
      fontSize: props.smallerText ? '0.7rem' : '0.75rem',
    };
  }, [props.smallerText]);

  const [foodDraftItems, beverageDraftItems] = useMemo(() => {
    return [
      props.draftItems.filter(i =>
        !i.item.food_ontology
        || (!!i.item.food_ontology && i.item.food_ontology[0] != 'beverage')
      ),
      props.draftItems.filter(i => !!i.item.food_ontology && i.item.food_ontology[0] == 'beverage'),
    ];
  }, [props.draftItems]);

  const totalFoodWeight = useMemo(() => {
    return getMealTotalWeight(foodDraftItems);
  }, [foodDraftItems]);
  const totalBeverageWeight = useMemo(() => {
    return getMealTotalWeight(beverageDraftItems);
  }, [beverageDraftItems]);

  const { getShowOnHoverProps } = useFoodDetailsPopup();

  const { getItemNutrientWithoutAddons, getItemNutrientTotal } = useMealNutrientValues({
    draftItems: props.draftItems,
  });

  const mkMealSummaryCell = useCallback((
    args: {
      render: (item: MealItem | MealItemCustomAddonResponse, parent: MealItem | null) => string | JSX.Element,
      showToolTip?: boolean,
    },
  ) => {
    return ({ row }: any) => {
      const { item, parent } = row?.original || {};
      if (!item) {
        return null;
      }
      const toolTip = args.showToolTip && item.custom_tip ? `Custom tip: ${item.custom_tip}` : '';
      return (
        <Tooltip title={toolTip}>
          <Typography
            sx={{
              fontSize: row?.original?.type == 'addon' ? addOnFontStyle.fontSize : summaryRowFontStyle.fontSize,
              fontWeight: 'inherit',
              overflow: 'hidden', // overflow styles only apply if width/maxWidth is set on this or a parent component
              textOverflow: 'ellipsis',
            }}
          >
            {args.render(item, parent)}
          </Typography>
        </Tooltip>
      );
    };
  }, [addOnFontStyle, summaryRowFontStyle]);

  const fmtItemWeight = useCallback((
    item: {
      serving_unit_amount: number,
      servings: number,
      percent_eaten?: number | null,
      food_ontology?: string[] | null,
    },
    parent: MealItem | null,
  ) => {
    const pctEaten = item.percent_eaten ?? parent?.percent_eaten ?? 1;
    const foodOntology = item.food_ontology ?? parent?.food_ontology ?? [];
    const weight = getItemWeightWithoutAddons(item, checkPctEaten(pctEaten));
    const foodOrBeverage = foodOntology[0] == 'beverage' ? 'beverage' : 'food';
    const totalWeight = foodOrBeverage == 'food' ? totalFoodWeight : totalBeverageWeight;
    return (
      <>
        <NutrientValueWithWarning
          nutrientName="weight_g"
          value={weight}
          valueStr={formatNumber(weight, 0) + 'g'}
        />
        {totalWeight > 0 && <span>&nbsp;({formatNumber(weight * 100 / totalWeight, 0)}%)</span>}
      </>
    );
  }, [totalBeverageWeight, totalFoodWeight]);

  // Push questions are only included when editing a queue
  const editor = useQueueItemEditor();
  const { mealQuestions } = usePushQuestions(
    useMemo(() => {
      return {
        patientId: editor.queueItem?.patient_id,
        mealId: editor.queueItem?.created_meal_id,
      };
    }, [editor.queueItem]),
  );

  const getMealPushQuestion = useCallback((item: MealItem) => {
    if (!mealQuestions?.length) {
      return undefined;
    }

    return (
      props.draftItems.find(d => d.id == item.id)?.pushQuestionUpdate
        ?? mealQuestions.find(q => q.meal_item_id == item.id)
    );
  }, [props.draftItems, mealQuestions]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Name',
        Cell: mkMealSummaryCell({
          render: (_item, parent) => {
            const item = _item as MealItem;
            if (!item.food_name) {
              return '';
            }

            const pushQuestion = getMealPushQuestion(item);
            const labelFlag = {
              [MealPushQuestionStatusEnum.Pendingsystem]: 'Question with answer',
              [MealPushQuestionStatusEnum.Pendingpatient]: 'Question awaiting patient response',
              [MealPushQuestionStatusEnum.Resolved]: 'Question resolved',
            };
            const flags = [
              item.custom_item && { short: 'C', name: 'Custom item', color: 'orange' },
              item.last_updated_by_user_id && item.last_updated_by_user_id == item.patient_id && {
                short: 'E',
                name: 'Edited last by patient',
                color: 'blue',
              },
              item.id && !!pushQuestion
              && {
                short: 'Q',
                name: (labelFlag as any)[pushQuestion.question_status as any] || 'Has question',
                color: getMealPushQuestionColor(pushQuestion),
              },
              item.custom_tip && {
                short: 'T',
                name: 'Custom tip: ' + item.custom_tip,
                color: 'blue',
              },
            ].filter(Boolean) as { short: string, name: string, color: string }[];

            return (
              <>
                <span {...getShowOnHoverProps({ mealItem: item })} style={{ cursor: 'pointer' }}>
                  {!!parent && '↳'}
                  {item.food_name_alias ?? item.food_name}
                </span>
                {flags.map(flag => (
                  <span
                    key={flag.short}
                    style={{
                      color: flag.color,
                      marginLeft: 2,
                      cursor: 'default',
                    }}
                    title={flag.name}
                  >
                    {flag.short}
                  </span>
                ))}
                &nbsp;
                {!props.hideNameLink && (
                  <Link
                    style={{ cursor: 'pointer' }}
                    to={getFoodEditorUrl(item.food_name)}
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      size="sm"
                      color="black"
                    />
                  </Link>
                )}
                {item.food_name_alias && (
                  <>
                    <br />
                    <Typography variant="caption" sx={{ color: 'gray' }}>
                      "{item.food_name}"
                    </Typography>
                  </>
                )}
              </>
            );
          },
          showToolTip: true,
        }),
      },

      {
        Header: 'Sizing',
        Cell: mkMealSummaryCell({
          render: (_item, parent) => {
            if (!_item.food_name) {
              return '';
            }
            return formatMealItemSizing(_item, parent, props.disableSizingColWrap ? 'normal' : 'nowrap');
          },
        }),
      },

      {
        Header: 'Weight',
        Cell: mkMealSummaryCell({
          render: (item, parent) => {
            if (!item.food_name) {
              return '';
            }
            return fmtItemWeight(item, parent);
          },
        }),
      },

      ...props.relevantNutrients.map(n => ({
        Header: n.label,
        Cell: mkMealSummaryCell({
          render: (item, parent) => {
            if (!item.food_name) {
              return '';
            }

            const pctEaten = (item as MealItem).percent_eaten ?? parent?.percent_eaten ?? 1;
            const res = getItemNutrientWithoutAddons(item, n.nutrient);

            if (parent) {
              // Item is an add-on
              res.value = res.value * checkPctEaten(pctEaten);
              res.valueStr = res.def.format(res.value);
            }

            return <MealItemNutrientValue value={res} />;
          },
        }),
      })),
    ];
  }, [
    mkMealSummaryCell,
    props.relevantNutrients,
    props.hideNameLink,
    props.disableSizingColWrap,
    getMealPushQuestion,
    getShowOnHoverProps,
    fmtItemWeight,
    getItemNutrientWithoutAddons,
  ]);

  const tableData: TableDataRow[] = useMemo(() => {
    const convertDraftItemToTableData = (draftItems: DraftItem[]) => {
      return draftItems.reduce((acc, v, i) => [
        ...acc,
        {
          draftItem: v,
          dragAndDropIndex: i,
          type: 'item',
          item: { ...v.item, id: v.id || undefined },
        } as TableDataMealItem,
        ...v.item.custom_addons?.map(addon => ({
          type: 'addon',
          item: addon,
          parent: v.item,
          mealItem: v,
          mealItemIndex: i,
        } as TableDataMealItemAddon)) ?? [],
      ], [] as TableDataRow[]);
    };

    const beverageData = convertDraftItemToTableData([...beverageDraftItems].reverse());
    const foodData = convertDraftItemToTableData([...foodDraftItems].reverse());
    const emptyRow = beverageData.length > 0 && foodData.length > 0
      ? [{ type: 'empty', item: getEmptyMealItem() } as TableDataEmpty]
      : [];

    return [...foodData, ...emptyRow, ...beverageData];
  }, [beverageDraftItems, foodDraftItems]);

  const getCellProps = (cell: any, curProps: any) => {
    const rowVal = cell.row.original;
    if (!rowVal) {
      return curProps;
    }
    const topBottomPadding = rowVal.type == 'addon' ? 5 : 8;
    return {
      ...curProps,
      style: {
        ...(curProps.style || {}),
        ...({
          paddingTop: topBottomPadding,
          paddingBottom: topBottomPadding,
        }),
      },
    };
  };

  const { palette } = useTheme();

  const summaryRows = useMemo(() => {
    // sticky styles only apply when inside a relative container that scrolls
    return (
      <>
        <TableRow sx={props.isSticky ? { position: 'sticky', zIndex: 3, bottom: '47px', background: '#fafafa' } : {}}>
          <TableCell
            sx={{
              fontSize: summaryRowFontStyle.fontSize,
              ...props.isSticky
                ? {
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                  backgroundColor: 'inherit',
                  borderRight: `1px solid ${palette.grey[200]}`,
                }
                : {},
            }}
          >
            {/* {foodCustomTip} */}
          </TableCell>
          <TableCell
            sx={{ ...summaryRowFontStyle, textAlign: 'right' }}
            colSpan={columns.length - props.relevantNutrients.length - 2}
          >
            Food Total
          </TableCell>
          <TableCell sx={summaryRowFontStyle}>
            <NutrientValueWithWarning
              nutrientName="weight_g"
              value={totalFoodWeight}
              valueStr={formatNumber(totalFoodWeight, 0) + 'g'}
            />
          </TableCell>
          {props.relevantNutrients.map(n => {
            const [def, totalVal] = foodDraftItems.reduce((acc, v) => {
              return [
                nutrientGetDef(n.nutrient),
                acc[1] + getItemNutrientTotal(v.item, n.nutrient),
              ];
            }, [null as NutrientDef | null, 0]);

            return (
              <TableCell key={n.label} sx={summaryRowFontStyle}>
                <NutrientValueWithWarning
                  nutrientName={def?.nutrient ?? ''}
                  value={totalVal}
                  valueStr={def?.format(totalVal) ?? '–'}
                />
              </TableCell>
            );
          })}
        </TableRow>
        <TableRow sx={props.isSticky ? { position: 'sticky', zIndex: 3, bottom: '0', background: '#fafafa' } : {}}>
          <TableCell
            sx={{
              fontSize: summaryRowFontStyle.fontSize,
              ...props.isSticky
                ? {
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                  backgroundColor: 'inherit',
                  borderRight: `1px solid ${palette.grey[200]}`,
                }
                : {},
            }}
          >
            {/* {beverageCustomTip} */}
          </TableCell>
          <TableCell
            sx={{ ...summaryRowFontStyle, textAlign: 'right' }}
            colSpan={columns.length - props.relevantNutrients.length - 2}
          >
            Beverage Total
          </TableCell>
          <TableCell sx={summaryRowFontStyle}>
            <NutrientValueWithWarning
              nutrientName="weight_g"
              value={totalBeverageWeight}
              valueStr={formatNumber(totalBeverageWeight, 0) + 'g'}
            />
          </TableCell>
          {props.relevantNutrients.map(n => {
            const [def, totalVal] = beverageDraftItems.reduce((acc, v) => {
              return [
                nutrientGetDef(n.nutrient),
                acc[1] + getItemNutrientTotal(v.item, n.nutrient),
              ];
            }, [null as NutrientDef | null, 0]);

            return (
              <TableCell key={n.label} sx={summaryRowFontStyle}>
                <NutrientValueWithWarning
                  nutrientName={def?.nutrient ?? ''}
                  value={totalVal}
                  valueStr={def?.format(totalVal) ?? '–'}
                />
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  }, [
    columns.length,
    getItemNutrientTotal,
    beverageDraftItems,
    foodDraftItems,
    props.relevantNutrients,
    props.isSticky,
    summaryRowFontStyle,
    totalBeverageWeight,
    totalFoodWeight,
  ]);

  return {
    columns,
    getCellProps,
    summaryRows,
    tableData,
  };
};

export const SummaryTable = (props: {
  draftItems: DraftItem[],
  isForConfirmationDialog?: boolean,
  relevantNutrients: RelevantNutrients,
  disableSizingColWrap?: boolean,
  smallerText?: boolean,
}) => {
  const {
    draftItems,
    isForConfirmationDialog,
    relevantNutrients,
    disableSizingColWrap,
    smallerText,
  } = props;

  const {
    columns,
    tableData,
    summaryRows,
    getCellProps,
  } = useMealTable(
    {
      draftItems,
      isForConfirmationDialog,
      relevantNutrients,
      disableSizingColWrap,
      smallerText,
    },
  );

  return (
    <MainCard content={false}>
      <ScrollX>
        <FilterSortTable
          columns={columns}
          data={tableData}
          striped={true}
          getCellProps={getCellProps}
          summaryRow={summaryRows}
          numRows={100}
        />
      </ScrollX>
    </MainCard>
  );
};

export const MealSummary = (props: {
  patientContext: PatientContext,
  queue: MealPhotoQueueResponse,
  draftItems: DraftItem[],
  isForConfirmationDialog?: boolean,
}) => {
  const relevantNutrients = useRelevantNutrients({
    context: 'summary',
    patientContext: props.patientContext,
  });

  return (
    <SummaryTable
      draftItems={props.draftItems}
      isForConfirmationDialog={props.isForConfirmationDialog}
      relevantNutrients={relevantNutrients}
    />
  );
};
